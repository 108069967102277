.rockstarProductItem {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  box-sizing: border-box;
  width: calc(25% - 26px);
  margin: 0 13px 26px 13px;
  position: relative;
}

.productItem {
  width: 100%;
  transition: 0.3s all;

  &:hover {
    transition: 0.3s all;
    .productImg img {
      transform: scale(1.1);
      transition: 0.3s all;
    }
  }
}

.productImg {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("https://www.datocms-assets.com/74813/1705613127-image.png");
  background-size: cover;

  img {
    max-height: 100%;
    margin-bottom: 0;
    outline: none;
    height: auto;
    max-width: 265px;
    width: 100%;
    transform: scale(0.9);
    transition: 0.3s all;
  }

  &:focus {
    img {
      outline: none !important;
    }
  }
}

.productItemDetail {
  display: block;
  padding-top: 10px;
  margin-top: 10px;
  transition: 0.3s all;

  h2 {
    font-family: "AktivGrotesk Ex Black Italic";
    color: var(--global--color-focus-tertiary);
    text-transform: uppercase;
    font-size: 20px;
    margin: 8px 0;
    transition: 0.3s all;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &:hover {
    h2 {
      color: var(--global--color-gold);
      transition: 0.3s all;
    }
  }
}

.productFlavorName {
  display: block;
  font-family: "AktivGrotesk Ex Black Italic";
  color: var(--global--color-focus-tertiary);
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;
  font-style: italic;

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.itemBottomDetails {
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    > * {
      width: 100%;
    }
  }
}

.productNutrition {
  display: block;
  font-size: 12px;
  line-height: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 16px;
  padding-top: 0;
  margin-top: 0;
  letter-spacing: 1.5px;
  color: var(--global--color-focus-tertiary);

  @media screen and (max-width: 767px) {
    order: 2;
  }
}

.productPrice {
  color: var(--global--color-focus-tertiary);
  text-transform: uppercase;
  font-size: 20px;
  line-height: normal;
  padding: 0 10px;
  font-family: "AktivGrotesk Ex Black Italic";
  font-weight: 900;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;

  @media screen and (max-width: 767px) {
    order: 1;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 3px;
  }
}
